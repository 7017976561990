import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import BudgetSubjectTable from '../components/budget_subject_table.vue';
import CustomerList from '../components/customer-list.vue';
import CompanyList from '../components/company-list.vue';

formCreate.component('BudgetSubjectForm', BudgetSubjectTable);
formCreate.component('CustomerList', CustomerList);
formCreate.component('CompanyList', CompanyList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20221229000000002',
      formFunctionCode: 'tpm_annual_fuzzy_award_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submit: false,
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'customerName') { // 选择客户
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_cutmoer_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              customerName: e[0].customerName,
              customerCode: e[0].customerCode,
            });
          },
        };
      } else if (v.field === 'companyName') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_company_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              companyName: e[0].companyName,
              companyCode: e[0].companyCode,
            });
          },
        };
      } else if (v.field === 'tpmAwardDetailReqVo') {
        v.props = {
          ...v.props,
        };
        v.value = {
          data: [],
        };
      } else if (v.field === 'totalAmount') {
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
          // controls: false,
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view' || this.id) {
        request
          .post('/tpm/tpmaward/query', {
            id: this.formConfig.row ? this.formConfig.row.id : this.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              request
                // .post('/tpm/tpmFeeBudgetController/chooseControl', {
                .post('/tpm/tpmFeeBudgetController/chooseControlQuery', {
                  feeBudgetCode: data.detailRespVo.budgetCode,
                  // ext2: this.formConfig.code === 'view' ? '1' : null,
                  ext2: '1',
                })
                .then((res1) => {
                  if (res1.success) {
                    const data1 = res1.result.data;
                    data.tpmAwardDetailReqVo = { data: data1 || [] };
                    const tpmAwardDetailReqVo = this.getRule('tpmAwardDetailReqVo');
                    tpmAwardDetailReqVo.value = data.tpmAwardDetailReqVo;
                  }
                });
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        if (!formData.tpmAwardDetailReqVo) {
          this.$message.error('请选择预算信息！');
          return false;
        }
        if (formData.tpmAwardDetailReqVo) {
          if (formData.tpmAwardDetailReqVo.data[0].canUseAmount < formData.totalAmount) {
            this.$message.error('申请金额不用大于预算信息可用余额！');
            return false;
          }
        }
        const tpmAwardDetailReqVo = formData.tpmAwardDetailReqVo.data;
        formData.tpmAwardDetailReqVo = {
          budgetCode: tpmAwardDetailReqVo[0].feeBudgetCode,
          budgetName: tpmAwardDetailReqVo[0].feeBudgetTypeName,
          budgetSubjectsCode: tpmAwardDetailReqVo[0].budgetSubjectsCode,
          budgetSubjectsName: tpmAwardDetailReqVo[0].budgetSubjectsName,
        };
        console.log(formData);
        let url = '/tpm/tpmaward/save';
        const params = { ...formData };
        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmaward/update';
          params.id = this.formConfig.row.id;
        }

        if (e === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
